import "./styles.scss"

import React from "react"

const Banner = () => {
  return (
    <section className="investment-banner">
      <div
        className="investment-banner__item"
        style={{
          backgroundImage: `url(${
            require("assets/images/investment/investment-banner-image.jpg")
              .default
          })`,
        }}
      ></div>
    </section>
  )
}

export default Banner
