import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import Content from "components/Content"

import {
  Tiles,
  Banner,
  Location,
  Convenience,
  Gallery,
} from "page_components/investment"

const Investment = () => {
  const title = "O inwestycji"

  return (
    <Layout seo={{ title: title }}>
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Content
        image={require("assets/images/investment/investment-image.jpg").default}
        isReverse
        hasShape
      >
        <h2>Nowoczesna enklawa spokoju dla żyjących w rytmie miasta!</h2>
        <p>
          Żyjesz w rytmie miasta? Chcesz być blisko tego co oferuje Kraków,
          Polska, świat, ale jednocześnie mieszkać otoczony spokojem i
          komfortem? Inwestycja City Łobzów dedykowana jest wszystkim tym,
          którzy działają dynamicznie, ale dom traktują jako enklawę spokoju i
          komfortowego wypoczynku. Tym ceniącym moc udogodnień i przemyślanych
          rozwiązań. Weź głęboki wdech i ruszaj w miasto, wróć wziąć głęboki
          wdech.
          <br />
          City Łobzów.
        </p>
      </Content>
      <Tiles />
      <Convenience />
      <Banner />
      <Location />
      <Gallery />
    </Layout>
  )
}

export default Investment
