import "./styles.scss"

import React from "react"

import tilesData from "data/tilesData"

const Tiles = () => {
  return (
    <section className="investment-tiles">
      <div className="container-fluid">
        <div className="row">
          {tilesData?.map((item, index) => (
            <div className="col-md-4" key={index}>
              <div
                className="investment-tiles__image"
                style={{ backgroundImage: `url('${item.image}')` }}
              />
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Tiles
