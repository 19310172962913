import "./styles.scss"

import React from "react"

import Button from 'components/Button'
import InteractiveMap from "components/InteractiveMap"

const Location = () => {
  return (
    <section className="investment-location">
      <div className="container-fluid">
        <div className="row">
            <div className="col-lg-7">
                <h3>Lokalizacja</h3>
            </div>
            <div className="col-lg-5">
                <div className="investment-location__cta">
                    <Button to="/lokalizacja/">Dowiedz się więcej</Button>
                </div>
            </div>
        </div>
        <InteractiveMap />
      </div>
    </section>
  )
}

export default Location
