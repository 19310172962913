import "./styles.scss"

import React, { useState } from "react"

import ModalImage from "components/ModalImage"

const Gallery = () => {
  const [image, setImage] = useState(null)

  return (
    <>
      <section className="investment-gallery">
        <div className="container-fluid">
          <h3>Galeria</h3>
          <div className="row">
            <div className="col-md-6">
              <GalleryItem
                image={require("assets/images/gallery/image-7.jpg").default}
                setImage={setImage}
              />
            </div>
            <div className="col-md-6">
              <GalleryItem
                image={require("assets/images/gallery/image-8.jpg").default}
                setImage={setImage}
              />
            </div>
          </div>
          <div className="row investment-gallery--row-small">
            <div className="col-md-4">
              <GalleryItem
                image={require("assets/images/gallery/image-5.jpg").default}
                setImage={setImage}
              />
            </div>
            <div className="col-md-4">
              <GalleryItem
                image={require("assets/images/gallery/image-3.jpg").default}
                setImage={setImage}
              />
            </div>
            <div className="col-md-4">
              <GalleryItem
                image={require("assets/images/gallery/image-10.jpg").default}
                setImage={setImage}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <GalleryItem
                image={require("assets/images/gallery/image-6.jpg").default}
                setImage={setImage}
              />
            </div>
            <div className="col-md-7">
              <GalleryItem
                image={require("assets/images/gallery/image-4.jpg").default}
                setImage={setImage}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <GalleryItem
                image={require("assets/images/gallery/image-1.jpg").default}
                setImage={setImage}
              />
            </div>
            <div className="col-md-6">
              <GalleryItem
                image={require("assets/images/gallery/image-2.jpg").default}
                setImage={setImage}
              />
            </div>
          </div>
          <div className="row investment-gallery--row-small">
            <div className="col-md-4">
              <GalleryItem
                image={require("assets/images/gallery/image-9.jpg").default}
                setImage={setImage}
              />
            </div>
            <div className="col-md-4">
              <GalleryItem
                image={require("assets/images/gallery/image-13.jpg").default}
                setImage={setImage}
              />
            </div>
            <div className="col-md-4">
              <GalleryItem
                image={require("assets/images/gallery/image-11.jpg").default}
                setImage={setImage}
              />
            </div>
          </div>
        </div>
      </section>
      <ModalImage image={image} setImage={setImage} />
    </>
  )
}

const GalleryItem = ({ image, setImage }) => (
  <button className="investment-gallery__item" onClick={() => setImage(image)}>
    <span
      className="investment-gallery__image"
      style={{ backgroundImage: `url(${image})` }}
    />
  </button>
)

export default Gallery
